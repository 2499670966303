const ServicesDesktop = ({ serviceLines = {} }) => {
	return (
		<div className='pb-3 service-desktop'>
			<span className='d-block fs-small'>Service</span>
			<div className='d-grid grid-auto-columns grid-template-areas-3 grid-gap-2'>

				{Object.keys(serviceLines).map((key) => {

					const service = serviceLines[key];
					const name = service.substring(0, service.indexOf(":"))

					let total;
					if(name === "Repaired By"){
						total = service.substring(service.indexOf(":")+1).trim();
					}else{
						total = service.substring(service.indexOf("$"), service.indexOf(".") + 2).trim()
					}
					const detail = service.substring(service.indexOf("(") + 1, service.indexOf(")") - 1).trim()
					
					if (!serviceLines[key].trim()) return '';

					return (
						<div key={key} className='border rounded p-2 d-flex  fs-sub mt-2'>
							<div className='d-flex justify-content-between align-items-center w-100'>
								<div className='w-25 d-flex flex-column justify-content-center'>
									<span className='font-weight-bold'>{name}</span>
									<span className='total'>{total}</span>
								</div>
								<span className='w-75 detail d-flex justify-content-end'>{detail}</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default ServicesDesktop
